<template>
  <div class="container-fluid page-title">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1>VIP accounts</h1>
      </div>
    </div>
    <div class="container-fluid justify-content-between">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-input type="email" v-model="form.email" name="Email" placeholder="Email" />
                  </div>
                  <div class="col-md-6">
                    <div class="text-center">
                      <button
                        type="submit"
                        :disabled="loadingPage"
                        class="btn btn-info btn-fill float-left"
                        @click.prevent="flagVipAccount">
                        Flag as VIP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="row pt-2">
        <div class="col-md-6">
          <input class="form-control" v-model="searchByEmail" type="text"
                 placeholder="Search by email"
                 aria-label="Search">
        </div>
        <div class="col-md-6">
          <div class="text-center">
            <button
              type="submit"
              :disabled="loadingPage || !this.canSearch"
              class="btn btn-info btn-fill float-left"
              @click.prevent="search">
              Search
            </button>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <table class="table row mx-0">
            <thead class="col-12 px-0 thead-light">
            <tr class="d-flex">
              <th class="col-4 collapse-text" scope="col">Email</th>
              <th class="col-4 collapse-text" scope="col">Created at</th>
              <th class="col-4 collapse-text" scope="col">Action</th>
            </tr>
            </thead>
            <tbody class="col-12 px-0">
            <tr class="d-flex"
                v-for="(vipAccount, index) in vipAccounts"
                :key="index">
              <td class="col-4 collapse-text">{{vipAccount.email}}</td>
              <td class="col-4 collapse-text" v-b-tooltip.hover :title="vipAccount.created_at">{{ vipAccount.created_at }}</td>
              <td class="col-4">
                <b-button v-b-tooltip.hover
                          size="sm"
                          title="Delete VipAccounts"
                          variant="light"
                          class="user-entry-action col-6 ml-2"
                          @click="deleteVipAccounts(vipAccount)">
                  <feather type="trash"></feather>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 d-flex justify-content-center">
          <b-pagination :disabled="loadingPage"
                        @change="changePage(currentPage++)"
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="itemsPerPage"
                        size="md">
          </b-pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { compose as Rcompose, length as Rlength, lt as Rlt } from 'ramda';

export default {
  name: 'VipAccounts',
  data() {
    return {
      vipAccounts: [],
      searchByEmail: '',
      loadingPage: false,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      loading: false,
      form: {
        email: null,
      },
    };
  },
  mounted() {
    this.requestVIPAccounts();
  },
  computed: {
    params() {
      return {
        params: {
          client: 'admin-ui',
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          search: this.searchByEmail || undefined,
        },
      };
    },
    canSearch() {
      if (!this.searchByEmail) {
        return true;
      }

      return Rcompose(Rlt(4), Rlength)(this.searchByEmail);
    },
  },
  watch: {
    currentPage() {
      this.requestVIPAccounts();
    },
  },
  methods: {
    validEmail(email) {
      const regex = [
        '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|',
        '(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|',
        '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      ].join('');

      return new RegExp(regex).test(email);
    },
    search() {
      if (this.canSearch) {
        this.requestVIPAccounts();
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    flagVipAccount() {
      if (!this.validEmail(this.form.email)) {
        this.$noty.error('Email is not valid');
        return;
      }

      this.loadingPage = true;

      axios.post('/v1/vip-accounts/', { email: this.form.email }).then(() => {
        this.$noty.success(`${this.form.email} flagged successfully.`);
        this.currentPage = 1;
        this.requestVIPAccounts();
      }).catch(err => {
        this.$noty.error('Error: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    deleteVipAccounts(vipAccount) {
      this.loadingPage = true;
      axios.delete(`/v1/vip-accounts/${vipAccount.vip_account_id}`).then(() => {
        this.$noty.success(`${vipAccount.email} VipAccounts removed.`);
        this.currentPage = 1;
        this.requestVIPAccounts();
      }).catch(err => {
        this.$noty.error('Error: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    requestVIPAccounts() {
      this.loadingPage = true;
      axios.get('/v1/vip-accounts', this.params).then(response => {
        this.vipAccounts = response.data.vip_accounts;
        this.total = response.data.total;
      }).catch(err => {
        this.$noty.error('Failed to fetch users: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
  },
};
</script>

<style scoped>
.page-title {
  margin-top: 1rem;
}
</style>
